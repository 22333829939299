// RoleBasedDashboard.js
import React from "react";
import { AllowedAccess } from "react-permission-role";
import CallsForApplicationPage from "layouts/CallsForApplicationComponent";
import CallForApplicationAndApplicationsStatistics from "./CallForApplicationAndApplicationsStatistics";
import UserManagementStatistics from "./UserManagementStatistics";
import {
  APPLICATIONS_SPECIALIST,
  APPLICATIONS_SUBMITTER,
  PORTAL_ADMINISTRATOR,
} from "features/constants/roles";

const MainDashboard = () => {
  return (
    <div>
      {/* Show UserManagementStatistics for Portal Administrator, or only if it’s the single role */}
      <AllowedAccess
        roles={[PORTAL_ADMINISTRATOR]}
        renderAuthFailed={null}
        conditions={(userRoles) =>
          userRoles.includes(PORTAL_ADMINISTRATOR) || userRoles.length === 1
        }
      >
        <UserManagementStatistics />
      </AllowedAccess>

      {/* Show CallForApplicationAndApplicationsStatistics if user has Applications Specialist, or only if it’s the single role */}
      <AllowedAccess
        roles={[APPLICATIONS_SPECIALIST]}
        renderAuthFailed={null}
        conditions={(userRoles) =>
          userRoles.includes(APPLICATIONS_SPECIALIST) || userRoles.length === 1
        }
      >
        <CallForApplicationAndApplicationsStatistics />
      </AllowedAccess>

      {/* Show CallsForApplicationPage for Applications Submitter, or only if it’s the single role */}
      <AllowedAccess
        roles={[APPLICATIONS_SUBMITTER]}
        renderAuthFailed={null}
        conditions={(userRoles) =>
          userRoles.includes(APPLICATIONS_SUBMITTER) || userRoles.length === 1
        }
      >
        <CallsForApplicationPage itemsPerPage={10} />
      </AllowedAccess>
    </div>
  );
};

export default MainDashboard;
