import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../authorization/authService";

export const feedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Feedback"],
  endpoints: (builder) => ({
    createFeedback: builder.mutation({
      query: (feedbackData) => ({
        url: "/feedback",
        method: "POST",
        body: feedbackData,
      }),
      invalidatesTags: ["Feedback"],
    }),
    submitUnregisteredUserFeedback: builder.mutation({
      query: (formData) => ({
        url: "/feedback/submit-unregistered-user",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const { useCreateFeedbackMutation, useSubmitUnregisteredUserFeedbackMutation } = feedbackApi;
