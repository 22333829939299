// src/services/userService.js
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../authorization/authService";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (showAll = false) => ({
        url: "/user/list_by_role",
        method: "GET",
        headers: {
          // Add any custom headers here if needed
        },
        params: { show_all: showAll },
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.data.items.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),

    getUserDetail: builder.query({
      query: (userId) => ({
        url: `/user/${userId}/detail`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),

    updateUserStatus: builder.mutation({
      query: ({ userId, status }) => ({
        url: `/user/${userId}/status`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    updateUserProfile: builder.mutation({
      query: (userUpdateData) => ({
        url: "/user/me",
        method: "PUT",
        body: userUpdateData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),

    getUserRolesWithStatus: builder.query({
      query: (userId) => `/role/${userId}/list`,
    }),

    deactivateUser: builder.mutation({
      query: ({ user_id }) => ({
        url: `user/deactivate/me`,
        method: "POST",
        body: { user_id },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),

    updateUserRoleStatus: builder.mutation({
      query: ({ userId, roleId, status }) => ({
        url: `user/${parseInt(userId)}/roles/${parseInt(roleId)}/status`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),

    getUserAnalyticsStatuses: builder.query({
      query: () => ({
        url: "/user/analytics/statuses",
        method: "GET",
      }),
      providesTags: [{ type: "UserAnalytics", id: "LIST" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserStatusMutation,
  useGetUserDetailQuery,
  useUpdateUserProfileMutation,
  useGetUserRolesWithStatusQuery,
  useDeactivateUserMutation,
  useUpdateUserRoleStatusMutation,
  useGetUserAnalyticsStatusesQuery,
} = userApi;
