import React from "react";
import { store } from "store/store";
import { Provider, useSelector } from "react-redux";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import AppProvider from "providers/AppProvider";
import { router } from "routes";
import { PermissionProvider } from "react-permission-role"; // Import PermissionProvider
import "helpers/initFA";

// Custom component to wrap PermissionProvider
const AppWithPermissions = () => {
  const { roles, permissions } = useSelector((state) => state.auth); // Access roles and permissions from Redux
  console.log("roles ", roles);

  return (
    <PermissionProvider roles={roles} permissions={permissions}>
      <RouterProvider router={router} />
    </PermissionProvider>
  );
};

const container = document.getElementById("main");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <AppWithPermissions />
      </AppProvider>
    </Provider>
  </React.StrictMode>
);
