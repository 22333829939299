export const VIEW_FILE = "view";
export const DOWNLOAD_FILE = "download";

export const fileActionHandler = async (url, token = null, action = VIEW_FILE) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const contentDisposition = response.headers.get("Content-Disposition");
    let fileName = "downloaded_file.pdf";

    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition.split("filename=")[1].split(";")[0].replace(/"/g, "");
    }

    const fileUrl = window.URL.createObjectURL(blob);

    if (action === DOWNLOAD_FILE) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute(DOWNLOAD_FILE, fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else if (action === VIEW_FILE) {
      window.open(fileUrl, "_blank"); // View the PDF in a new tab
    }
  } catch (error) {
    console.error("Failed to process the file", error);
    throw error;
  }
};
