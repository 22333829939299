import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import ProductShareChart from "components/dashboards/e-commerce/ProductShare/ProductShareChart";
import { ApplicationStatusEnum } from "features/constants/application";

const ProductShareItem = ({ item, index }) => {
  const { name, color, value } = item;

  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semibold fs-11 ${index === 0 ? "mt-3" : ""}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color.split("-")[1] || color || "text-info"}`}
        />
        {name}
      </p>
      <div className="d-xxl-none">{value}</div>
    </Flex>
  );
};

const ProductShare = ({ analyticsData }) => {
  if (!analyticsData || !analyticsData.applications_summary) return null;

  const submitted =
    analyticsData.applications_summary.find(
      (status) => status.name === ApplicationStatusEnum.SUBMITTED
    )?.value || 0;
  const validated =
    analyticsData.applications_summary.find(
      (status) => status.name === ApplicationStatusEnum.VALIDATED
    )?.value || 0;

  const percentage = ((validated / (validated + submitted)) * 100).toFixed(1);
  const total = submitted + validated;

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            <h6 className="mt-1">Application Statuses</h6>
            {analyticsData.applications_summary.map((item, index) => (
              <ProductShareItem key={index} item={item} index={index} />
            ))}
          </Col>
          <Col xs="auto" className="position-relative d-flex flex-column align-items-center">
            <div className="position-relative" style={{ width: "6.625rem", height: "6.625rem" }}>
              <ProductShareChart analyticsData={analyticsData} />
              <h2
                className="position-absolute top-50 start-50 translate-middle text-700 fw-normal lh-1 fs-8 text-center"
                style={{ marginTop: 30 }}
              >
                {percentage}%
              </h2>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductShare;
