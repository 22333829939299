import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useAppContext } from "providers/AppProvider";

const ProductShareChart = ({ analyticsData }) => {
  const { getThemeColor } = useAppContext();

  const labels = analyticsData?.applications_summary.map((status) => status.name);
  const values = analyticsData?.applications_summary.map((status) => status.value);
  const colors = analyticsData?.applications_summary.map((status) => getThemeColor(status.color));

  console.log("labels ", labels);

  const options = {
    tooltip: {
      padding: [7, 10],
      backgroundColor: getThemeColor("gray-100"),
      borderColor: getThemeColor("gray-300"),
      textStyle: { color: getThemeColor("gray-1100") },
      borderWidth: 1,
      formatter: (params) => `<strong>${params.data.name}:</strong> ${params.percent}%`,
    },
    rotation: -90,
    circumference: 180,
    cutout: "80%",
    plugins: {
      legend: { display: false },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  return <Doughnut data={data} options={options} width="112" />;
};

export default ProductShareChart;
