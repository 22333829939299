import React from "react";
import { Link } from "react-router-dom";
import AuthCardLayout from "layouts/AuthCardLayout";

import { Button } from "react-bootstrap";
import paths from "routes/paths";
import TechnicalIssueForm from "../TechnicalIssueForm";

const TechnicalIssue = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <>
          <p className="pt-3 text-white">
            Have an account?
            <br />
            <Button as={Link} variant="outline-light" className="mt-2 px-4" to={paths.cardLogin}>
              Log In
            </Button>
          </p>
        </>
      }
      footer={false}
    >
      <h3>Technical Issue Form</h3>
      <TechnicalIssueForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default TechnicalIssue;
