import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

const SaasConversion = ({ data }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center">
          <Col className="d-md-flex d-lg-block flex-between-center">
            <h6 className="mb-md-0 mb-lg-2">Pending Roles</h6>
          </Col>
          <Col xs="auto">
            <h4 className="fs-6 fw-normal text-primary">
              {data}
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SaasConversion.propTypes = {
  data: PropTypes.number.isRequired,
};

export default SaasConversion;
