import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { InputGroup } from "react-bootstrap";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  useGetRolesByBankQuery,
  useGetBanksQuery,
  useRegisterUserMutation,
} from "components/services/authorization/authService";
import paths from "routes/paths";
import Select from "react-select";
import { FaArrowsRotate } from "react-icons/fa6";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { validatePassword } from "components/utilities/sedra3/helpers";
import MaskedInput from "react-text-mask";
import { useGetBankQuery } from "components/services/bank/bankService";
import { PHONE_INPUT_MASK } from "features/constants/users";

const RegistrationForm = ({ hasLabel }) => {
  const navigate = useNavigate();

  // Form handling with React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger, // Used to manually trigger validation on mount
  } = useForm({ mode: "onChange" });

  // State
  const [captchaData, setCaptchaData] = useState({ captchaUrl: "", captchaId: "" });
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [showAdminWarning, setShowAdminWarning] = useState(false); // State to show/hide the warning text
  const [selectedRoles, setSelectedRoles] = useState([]); // State to manage roles

  const dashes = "--------";

  const [registerUser] = useRegisterUserMutation();
  const { data: banksData } = useGetBanksQuery();
  const { data: rolesData, refetch: refetchRoles } = useGetRolesByBankQuery(watch("bankId"), {
    skip: !watch("bankId"),
  });

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/captcha`);
      const data = await response.json();
      setCaptchaData({
        captchaUrl: `data:image/png;base64,${data.image}`, // Assuming image is sent as base64 encoded
        captchaId: data.captcha_id,
      });
      setCaptchaError("");
    } catch (error) {
      console.error("Failed to fetch CAPTCHA:", error);
      toast.error("Failed to load CAPTCHA. Please try again.");
    }
  };

  useEffect(() => {
    fetchCaptcha();
    trigger(); // Trigger validation on mount
  }, [trigger]);

  const { data: bankAdminData } = useGetBankQuery(watch("bankId"), {
    skip: !watch("bankId"), // Skip query if no bank is selected
  });

  useEffect(() => {
    if (watch("bankId")) {
      refetchRoles();
    }
  }, [watch("bankId"), refetchRoles]);
  useEffect(() => {
    if (bankAdminData?.data === null) {
      // No bank administrator found, show the warning
      setShowAdminWarning(true);
    } else {
      // Bank administrator exists, hide the warning
      setShowAdminWarning(false);
    }
  }, [bankAdminData]);

  // Role handling logic
  const handleRolesChange = (selectedOptions) => {
    const roleIds = selectedOptions ? selectedOptions.map((role) => role.value) : [];
    setSelectedRoles(selectedOptions);

    // Manually set the value and trigger validation
    setValue("roles", roleIds, { shouldValidate: true });

    // Trigger validation for roles to check if at least one role is selected
    trigger("roles");
  };

  // Submit handler
  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match!", {
        theme: "colored",
      });
      return;
    }

    const userData = {
      new_user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.telephone,
        bank_id: parseInt(data.bankId),
        password: data.password,
        is_active: true,
        role_ids: data.roles,
      },
      captcha: {
        captcha_id: captchaData.captchaId,
        captcha_input: captchaInput,
      },
    };

    try {
      await registerUser(userData).unwrap();
      toast.success(`Registration request has been submitted`, {
        theme: "colored",
      });
      navigate(paths.cardLogin);
    } catch (error) {
      toast.error(`Failed to register: ${error.data?.detail}`, {
        theme: "colored",
      });
    }
  };

  const togglePasswordVisibility = () => setIsShowPassword(!isShowPassword);
  const toggleConfirmPasswordVisibility = () => setIsShowConfirmPassword(!isShowConfirmPassword);

  // Render form
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "First Name" : ""}
          isInvalid={!!errors.firstName}
          {...register("firstName", {
            required: "First Name is required",
          })}
        />
        <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Last Name" : ""}
          isInvalid={!!errors.lastName}
          {...register("lastName", {
            required: "Last Name is required",
          })}
        />
        <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          isInvalid={!!errors.email}
          {...register("email", {
            required: "Email field is required",
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: "Invalid email address",
            },
          })}
        />
        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{`Contact number (mobile)`}</Form.Label>}
        <MaskedInput
          mask={PHONE_INPUT_MASK}
          className={`form-control ${errors.telephone ? "is-invalid" : ""}`}
          guide={false}
          placeholder="+977 (XXX) XXX XX XX"
          {...register("telephone", {
            required: "Telephone is required",
          })}
          onChange={(e) => {
            setValue("telephone", e.target.value);
            trigger("telephone");
          }}
        />
        <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Bank</Form.Label>}
        <Form.Control
          as="select"
          name="bankId"
          isInvalid={!!errors.bankId}
          {...register("bankId", {
            required: "Bank is required",
          })}
        >
          <option value="">{dashes}</option>
          {banksData?.data.items.map((bank) => (
            <option key={bank.id} value={bank.id}>
              {`${bank.name} (class ${bank.bank_class})`}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.bankId?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Roles</Form.Label>}

        {showAdminWarning && (
          <div className="text-danger mb-3" style={{ fontSize: "0.75rem" }}>
            There is no user with an administrator role assigned from your bank yet. To speed up the
            user registration process, an administrator should be assigned from your bank who will
            then approve further user registrations from your bank.
          </div>
        )}

        <Select
          isMulti
          name="roles"
          options={rolesData?.map((role) => ({ value: role.id, label: role.name }))}
          className={`basic-multi-select ${errors.roles ? "is-invalid" : ""}`}
          classNamePrefix="select"
          placeholder={dashes}
          onChange={handleRolesChange}
          value={selectedRoles}
          isDisabled={!watch("bankId") || rolesData?.length === 0}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 10, // Set a higher z-index for the dropdown menu
            }),
          }}
        />
        <input
          type="hidden"
          {...register("roles", {
            required: "At least one role must be selected", // Validation rule for roles
            validate: () => selectedRoles.length > -1 || "At least one role must be selected", // Custom validation function
          })}
        />
        <Form.Control.Feedback type="invalid">{errors.roles?.message}</Form.Control.Feedback>

        {selectedRoles.length > 0 && (
          <div className="mt-2">
            <p
              style={{
                fontSize: "0.9rem",
                color: "blue",
                marginBottom: "0.2rem",
                padding: "0.2rem",
              }}
            >
              For the selected roles, you will be able to:
            </p>
            <ul>
              {rolesData
                ?.filter((role) => selectedRoles.some((selected) => selected.value === role.id))
                .map((role) => (
                  <li key={role.id} style={{ fontSize: "0.9rem", color: "blue" }}>
                    {role.description}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <InputGroup>
            <Form.Control
              placeholder={!hasLabel ? "Password" : ""}
              type={isShowPassword ? "text" : "password"}
              isInvalid={!!errors.password}
              {...register("password", {
                required: "Password field is required",
                validate: validatePassword,
              })}
            />
            <Button
              variant="outline-secondary"
              onClick={togglePasswordVisibility}
              id="button-addon1"
              style={{ width: "40px", padding: 0, textAlign: "center" }}
            >
              {isShowPassword ? <FaEyeSlash /> : <FaEye />}
            </Button>
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <InputGroup>
            <Form.Control
              placeholder={!hasLabel ? "Confirm Password" : ""}
              type={isShowConfirmPassword ? "text" : "password"}
              isInvalid={!!errors.confirmPassword}
              {...register("confirmPassword", {
                required: "Confirm Password is required",
                validate: (value) => value === watch("password") || "Passwords do not match",
              })}
            />
            <Button
              variant="outline-secondary"
              onClick={toggleConfirmPasswordVisibility}
              id="button-addon2"
              style={{ width: "40px", padding: 0, textAlign: "center" }}
            >
              {isShowConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>CAPTCHA</Form.Label>
        <div className="d-flex align-items-center">
          <img
            src={captchaData.captchaUrl}
            alt="CAPTCHA"
            style={{ cursor: "pointer", marginBottom: "10px", marginRight: "10px" }}
            title="Click to reload CAPTCHA"
          />
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-refresh">Refresh CAPTCHA</Tooltip>}
          >
            <span>
              <FaArrowsRotate
                onClick={fetchCaptcha}
                style={{ cursor: "pointer", color: "#007bff", fontSize: "1.5rem" }}
              />
            </span>
          </OverlayTrigger>
        </div>
        <Form.Control
          type="text"
          placeholder="Enter CAPTCHA here"
          value={captchaInput}
          onChange={(e) => setCaptchaInput(e.target.value)} // Set CAPTCHA input directly
        />
        <Form.Control.Feedback type="invalid">{captchaError}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !watch("firstName") ||
            !watch("lastName") ||
            !watch("email") ||
            !watch("password") ||
            !watch("confirmPassword") ||
            !watch("bankId") ||
            !watch("roles") ||
            !captchaInput
          }
        >
          Register
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
