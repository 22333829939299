import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { PieChart } from "echarts/charts";
import * as echarts from "echarts/core";
import BasicECharts from "components/common/BasicEChart";
import { GridComponent, TitleComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useAppContext } from "providers/AppProvider";

echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer]);

const getOptions = (getThemeColor, data, radius, total) => ({
  color: data.map((item) => getThemeColor(item.color)),
  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getThemeColor("gray-100"),
    borderColor: getThemeColor("gray-300"),
    textStyle: { color: getThemeColor("gray-1100") },
    borderWidth: 1,
    formatter: (params) =>
      `<strong>${params.data.name}:</strong> ${params.data.value} (${params.percent}%)`,
  },
  series: [
    {
      name: total,
      type: "pie",
      radius,
      avoidLabelOverlap: false,
      emphasis: { scale: false },
      itemStyle: { borderWidth: 2, borderColor: getThemeColor("gray-100") },
      label: {
        show: true,
        position: "center",
        formatter: `{a}`,
        fontSize: 23,
        color: getThemeColor("gray-1100"),
      },
      data,
    },
  ],
});

const MarketShareItem = ({ item, index, total }) => {
  const { name, color, value } = item;
  // const percentage = ((value * 100) / total).toFixed(0);
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semibold fs-11 ${index === 0 && "mt-3"}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color.split("-")[1] || color || "text-info"}`}
        />
        {name}
      </p>
      <div className="d-xxl-none">{value}</div>
    </Flex>
  );
};

const MarketShare = ({ data, radius }) => {
  const { getThemeColor } = useAppContext();
  const total = data.reduce((acc, val) => acc + val.value, 0);

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            <h6 className="mt-1">Calls for Application status</h6>
            {data.map((item, index) => (
              <MarketShareItem item={item} index={index} key={index} total={total} />
            ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(getThemeColor, data, radius, total)}
                style={{ width: "6.625rem", height: "6.625rem" }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MarketShareItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

MarketShare.propTypes = {
  data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired,
};

export default MarketShare;
