import React, { useEffect, useState, forwardRef } from "react";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import FalconComponentCard from "components/common/FalconComponentCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  useGetSubmittedApplicationsQuery,
  useGetApplicationByIdQuery,
  useDeleteApplicationMutation,
} from "components/services/application/ApplicationService";
import ApplicationForm from "components/dashboards/SEDRA3/ApplicationForm";
import SubtleBadge from "components/common/SubtleBadge";
import { AllowedAccess } from "react-permission-role"; // Import AllowedAccess for role-based protection
import { Navigate } from "react-router-dom"; // Import for redirect if access is denied
import paths from "routes/paths"; // Adjust the path based on your routes setup
import { PORTAL_ADMINISTRATOR, APPLICATIONS_SPECIALIST } from "features/constants/roles";
import Error401 from "components/errors/Error401";

const SubmittedApplications = () => {
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [initialData, setInitialData] = useState(null);

  const { data: applicationsData, refetch } = useGetSubmittedApplicationsQuery({
    show_all: showAll,
  });
  const { data: applicationData, refetch: refetchApplicationData } = useGetApplicationByIdQuery(
    applicationId,
    { skip: !applicationId }
  );
  const [deleteApplication] = useDeleteApplicationMutation();

  useEffect(() => {
    if (applicationData) {
      setInitialData(applicationData.data);
    }
  }, [applicationData]);

  const handleCloseModal = () => {
    setShowModal(false);
    setApplicationId(null);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    setApplicationId(id);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDetail = (id) => {
    setApplicationId(id);
    setIsEdit(false);
    setShowModal(true);
  };

  const handleApplicationCreated = async () => {
    handleCloseModal();
    refetch();
  };

  const IconWithTooltip = forwardRef(({ icon, tooltip, onClick, className }, ref) => (
    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
      <span ref={ref} onClick={onClick} className={className}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </OverlayTrigger>
  ));

  const columns = [
    {
      accessorKey: "PFI",
      header: "Type",
      cell: ({ row }) => (
        <span className="text-primary cursor-pointer" onClick={() => handleDetail(row.original.id)}>
          Application
        </span>
      ),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "id",
      header: "Number",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => original.id_call_application,
    },
    {
      accessorKey: "bank.name",
      header: "Bank",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => original.bank.name,
    },
    {
      accessorKey: "application_amnt",
      header: "Amount",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => `NRP ${original.application_amnt}`,
    },
    {
      accessorKey: "status",
      header: "Status",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => {
        const isDrafted = original.status === "Drafted";
        return (
          <SubtleBadge
            pill
            bg={isDrafted ? "danger" : "success"}
            style={isDrafted ? { backgroundColor: "#f8d7da", color: "#721c24" } : {}}
          >
            <span>{original.status}</span>
          </SubtleBadge>
        );
      },
    },
    {
      accessorKey: "score",
      header: "Score",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => original.score,
    },
    {
      accessorKey: "updated_at",
      header: "Last update",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => new Date(original.updated_at).toLocaleString(),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => {
        if (row.original.status !== "Drafted") {
          return (
            <div className="d-flex justify-content-around">
              <IconWithTooltip
                icon={faEdit}
                tooltip="Edit"
                onClick={() => handleEdit(row.original.id)}
                className="text-primary cursor-pointer"
              />
            </div>
          );
        }
        return null;
      },
      meta: {
        headerProps: { className: "text-900 text-start" },
        cellProps: { className: "text-center" },
      },
    },
  ];

  const data = applicationsData ? applicationsData.data : [];

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
    globalFilter: true,
  });

  return (
    <>
      <FalconComponentCard className="mb-0">
        <FalconComponentCard.Header
          title="Submitted Applications"
          light={false}
          className="border-bottom border-200"
        />
        <FalconComponentCard.Body>
          <AdvanceTableProvider {...table}>
            <Row className="center mt-1 mb-2">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox />
              </Col>
              <Col xs="auto" className="ms-auto">
                <Form.Check
                  type="checkbox"
                  id="show-all-applications"
                  label="Show All Applications"
                  checked={showAll}
                  onChange={() => setShowAll(!showAll)}
                  className="mb-3"
                />
              </Col>
            </Row>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
            </div>
          </AdvanceTableProvider>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      {/* Application Form Modal */}
      <ApplicationForm
        show={showModal}
        handleClose={handleCloseModal}
        initialData={initialData}
        isEdit={isEdit}
        isDetail={!isEdit}
        submitButtonLabel="Return to bank"
        submitButtonStyle="danger"
        onApplicationCreated={handleApplicationCreated}
        showValidateButton={true}
      />
    </>
  );
};

export default SubmittedApplications;
