export const REPAYMENT_PRINCIPAL_OPTIONS = {
  SEMI_ANNUAL: "Semi-annual",
  QUARTERLY: "Quarterly",
  ANNUAL: "Annual",
};

export const PAYMENT_INTEREST_OPTIONS = {
  SEMI_ANNUAL: "Semi-annual",
  QUARTERLY: "Quarterly",
  ANNUAL: "Annual",
};
