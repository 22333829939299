import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SubmittedApplications from "components/dashboards/SEDRA3/SubmittedApplications";
import MarketShare from "components/dashboards/SEDRA3/sedra-widgets/MarketShare";
import ProductShare from "components/dashboards/SEDRA3/sedra-widgets/ProductShare";
import { useGetCallsAnalyticsStatusesQuery } from "components/services/call-application/CallApplicationService";
import { useGetApplicationAnalyticsStatusesQuery } from "components/services/application/ApplicationService";

const CallForApplicationAndApplicationsStatistics = () => {
  const [callsData, setCallsData] = useState(null);
  const [applicationsData, setApplicationsData] = useState(null);

  const {
    data: callsAnalyticsStatuses,
    isLoading: isCallsAnalyticsStatusesLoading,
    error: callsAnalyticsStatusesLoadingError,
  } = useGetCallsAnalyticsStatusesQuery();

  const {
    data: applicationAnalyticsStatuses,
    isLoading: isApplicationAnalyticsStatusesLoading,
    error: applicationAnalyticsStatusesLoadingError,
  } = useGetApplicationAnalyticsStatusesQuery();

  // Update states when API data is available
  useEffect(() => {
    if (callsAnalyticsStatuses) setCallsData(callsAnalyticsStatuses);
  }, [callsAnalyticsStatuses]);

  useEffect(() => {
    if (applicationAnalyticsStatuses) setApplicationsData(applicationAnalyticsStatuses);
  }, [applicationAnalyticsStatuses]);

  return (
    <div>
      {/* Container with gap between SubmittedApplications and analytics */}
      <div className="mb-3">
        <Row className="g-3">
          <Col md={6}>
            {/* MarketShare Component */}
            {isCallsAnalyticsStatusesLoading ? (
              <p>Loading calls analytics statuses...</p>
            ) : callsAnalyticsStatusesLoadingError ? (
              <p>Error loading calls analytics statuses.</p>
            ) : (
              callsData && <MarketShare data={callsData} radius={["100%", "87%"]} />
            )}
          </Col>

          <Col md={6}>
            {/* ProductShare Component */}
            {isApplicationAnalyticsStatusesLoading ? (
              <p>Loading application analytics statuses...</p>
            ) : applicationAnalyticsStatusesLoadingError ? (
              <p>Error loading application analytics statuses.</p>
            ) : (
              applicationsData && <ProductShare analyticsData={applicationsData} />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-4">
        <SubmittedApplications />
      </div>
    </div>
  );
};

export default CallForApplicationAndApplicationsStatistics;
