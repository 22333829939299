import React from "react";
import { Link } from "react-router-dom";
import AuthCardLayout from "layouts/AuthCardLayout";
import RegistrationForm from "components/authentication/RegistrationForm";
import { Button } from "react-bootstrap";
import paths from "routes/paths";
import { toast } from "react-toastify";
import { VIEW_FILE, fileActionHandler } from "components/utilities/sedra3/file-downloader";

const Registration = () => {
  const handleViewManual = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/login/view-registration-manual`;
      await fileActionHandler(url, null, VIEW_FILE); // Call fileActionHandler to view the file
    } catch (error) {
      console.error("Failed to load the registration manual:", error);
      toast.error("Error loading registration manual");
    }
  };

  return (
    <AuthCardLayout
      leftSideContent={
        <>
          <p className="pt-3 text-white">
            Have a technical issue?
            <br />
            <Button
              as={Link}
              variant="outline-light"
              className="mt-2 px-4"
              to={paths.cardTechnicalIssue}
            >
              Raise a ticket
            </Button>
          </p>
          <p className="pt-3 text-white">
            Have an account?
            <br />
            <Button as={Link} variant="outline-light" className="mt-2 px-4" to={paths.cardLogin}>
              Log In
            </Button>
          </p>
          <p className="mt-3">
            <Button variant="link" className="text-white" onClick={handleViewManual}>
              Registration manual
            </Button>
          </p>
        </>
      }
      footer={false}
    >
      <h3>Register</h3>
      <RegistrationForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Registration;
