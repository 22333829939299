import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, OverlayTrigger, Tooltip, Modal, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import useAdvanceTable from "hooks/useAdvanceTable";
import {
  useDeleteApplicationMutation,
  useGetApplicationByIdQuery,
} from "components/services/application/ApplicationService";
import { APPLICATIONS_SUBMITTER, VIEWER_OF_APPLICATIONS } from "features/constants/roles";
import SubtleBadge from "components/common/SubtleBadge";

const ActiveCases = ({
  applications,
  onEdit,
  onDetail,
  refetchApplications,
  refetchCallApplications,
  roles,
}) => {
  const [deleteApplication] = useDeleteApplicationMutation();
  const [viewedApplicationId, setViewedApplicationId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control the delete confirmation modal
  const [applicationToDelete, setApplicationToDelete] = useState(null); // Store the application to delete

  const canSubmitApplications = roles.includes(APPLICATIONS_SUBMITTER);
  const canOnlyViewApplications = roles.includes(VIEWER_OF_APPLICATIONS) && !canSubmitApplications;

  // Fetch the application data when viewedApplicationId changes
  const { data: applicationData, refetch } = useGetApplicationByIdQuery(viewedApplicationId, {
    skip: !viewedApplicationId, // Skip query if no application is selected
  });

  useEffect(() => {
    if (showModal && viewedApplicationId) {
      refetch(); // Refetch the application data when the modal opens
    }
  }, [showModal, viewedApplicationId, refetch]);

  const handleDelete = async () => {
    try {
      await deleteApplication(applicationToDelete).unwrap();
      refetchApplications();
      refetchCallApplications();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete application", error);
    }
  };

  const handleDeleteClick = (id) => {
    setApplicationToDelete(id); // Set the application to be deleted
    setShowDeleteModal(true);
  };

  const IconWithTooltip = forwardRef(({ icon, tooltip, onClick, className, disabled }, ref) => (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <span
        ref={ref}
        onClick={disabled ? undefined : onClick}
        className={`${className} ${disabled ? "disabled-icon" : ""}`}
        style={{ cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }} // Styling to indicate disabled state
      >
        <FontAwesomeIcon icon={icon} />
      </span>
    </OverlayTrigger>
  ));

  const columns = [
    {
      accessorKey: "type",
      header: "Type",
      cell: ({ row }) => (
        <span
          className="text-primary cursor-pointer"
          onClick={() => onDetail(row.original.id, row.original.call_application_id)}
        >
          Application
        </span>
      ), // Since all rows are for applications
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "id_call_application",
      header: "Number",
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "created_at",
      header: "Created",
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "updated_at",
      header: "Last update",
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "user_action_req",
      header: "User actions required",
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? (
          <SubtleBadge pill bg="warning">
            <span>Yes</span>
          </SubtleBadge>
        ) : (
          "No"
        );
      },
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const isSubmitted = row.original.status === "Submitted"; // Check if the application is submitted
        return !isSubmitted ? ( // Hide buttons if the application is submitted
          <div className="d-flex justify-content-around">
            <IconWithTooltip
              icon={faEdit}
              tooltip="Edit"
              onClick={() => onEdit(row.original.id, row.original.call_application_id)} // Use onEdit passed from parent
              className="text-primary cursor-pointer"
              disabled={canOnlyViewApplications}
            />
            <IconWithTooltip
              icon={faTrashAlt}
              tooltip="Delete"
              onClick={() => handleDeleteClick(row.original.id)} // Trigger the delete confirmation modal
              className="text-danger cursor-pointer"
              disabled={canOnlyViewApplications}
            />
          </div>
        ) : null;
      },
      meta: {
        headerProps: { className: "text-900" },
      },
    },
  ];

  const table = useAdvanceTable({
    data: applications,
    columns,
    sortable: true,
    pagination: false,
  });

  return (
    <Card>
      <Card.Header>
        <h5>Active Cases</h5>
      </Card.Header>
      <Card.Body>
        <AdvanceTableProvider {...table}>
          <div className="table-responsive scrollbar">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </div>
        </AdvanceTableProvider>
      </Card.Body>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        className="call-application-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger me-2" />
            Confirm Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this application? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

ActiveCases.propTypes = {
  applications: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired, // Add onDetail as required prop
  refetchApplications: PropTypes.func.isRequired,
  refetchCallApplications: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default ActiveCases;
