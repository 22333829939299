import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authorization/authService';

export const bankApi = createApi({
  reducerPath: 'bankApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Bank'], // Updated tag type to 'BankAdmin'
  endpoints: (builder) => ({
    getBank: builder.query({
      query: (bankId) => ({
        url: `/bank/${bankId}/bank_admin`,
        method: 'GET',
      }),
      providesTags: (result, error, bankId) => [{ type: 'Bank', bankId }], // Updated tag
    }),
  }),
});

export const { useGetBankQuery } = bankApi;
