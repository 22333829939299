import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarTop from "components/navbar/top/NavbarTop";
import { useAppContext } from "providers/AppProvider";
import { AllowedAccess } from "react-permission-role";
import CallsForApplicationPage from "layouts/CallsForApplicationComponent";

import {
  APPLICATIONS_SPECIALIST,
  APPLICATIONS_SUBMITTER,
  PORTAL_ADMINISTRATOR,
} from "features/constants/roles";
import CallForApplicationAndApplicationsStatistics from "./CallForApplicationAndApplicationsStatistics";
import UserManagementStatistics from "./UserManagementStatistics";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const {
    config: { isFluid },
  } = useAppContext();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      <NavbarTop />
      <Outlet /> {/* Outlet for any nested routes */}
    </div>
  );
};

export default MainLayout;
