export const PORTAL_ADMINISTRATOR = "Portal Administrator";
export const APPLICATIONS_SPECIALIST = "Applications Specialist";
export const REPORTS_SPECIALIST = "Reports Specialist";
export const BANK_ADMINISTRATOR = "Bank Administrator";
export const VIEWER_OF_APPLICATIONS = "Viewer of Applications";
export const VIEWER_OF_REPORTS = "Viewer of Reports";
export const APPLICATIONS_SUBMITTER = "Applications Submitter";
export const REPORTS_SUBMITTER = "Reports Submitter";

export const ALL_ROLES = [
  PORTAL_ADMINISTRATOR,
  APPLICATIONS_SPECIALIST,
  REPORTS_SPECIALIST,
  BANK_ADMINISTRATOR,
  VIEWER_OF_APPLICATIONS,
  VIEWER_OF_REPORTS,
  APPLICATIONS_SUBMITTER,
  REPORTS_SUBMITTER,
];

export const MODAL_TEXTS = {
  CONFIRM_ROLE_DEACTIVATION_TITLE: "Confirm Role Deactivation",
  CONFIRM_ROLE_DEACTIVATION_TEXT:
    "Are you sure you want to deactivate this role? This action cannot be undone.",
  ROLE_DEACTIVATION_BUTTON: "Deactivate Role",
  CANCEL_BUTTON: "Cancel",
};
