import React, { useState } from "react";
import { useLoginUserMutation } from "components/services/authorization/authService";
import LoginForm from "components/authentication/LoginForm";
import AuthCardLayout from "layouts/AuthCardLayout";
import paths, { rootPaths } from "routes/paths";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { decodeJWT, setCredentials } from "features/auth/authSlice";
import { toast } from "react-toastify";
import { setAccessToken, setRefreshToken } from "components/utilities/sedra3/auth";
import { usePermission } from "react-permission-role"; // Import usePermission

const Login = () => {
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser } = usePermission(); // Destructure setUser from usePermission

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async (email, password) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const response = await loginUser({ email, password }).unwrap();
      const { access_token, refresh_token, user } = response.data;

      // Decode token to extract roles and permissions
      const decodedToken = decodeJWT(access_token);
      const roles = decodedToken?.roles || [];
      const permissions = decodedToken?.permissions || [];

      console.log("permissions ", permissions);

      // Dispatch user info to Redux store
      dispatch(
        setCredentials({
          user: user,
          accessToken: access_token,
        })
      );
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      localStorage.setItem("user", JSON.stringify(user));

      // Set user roles and permissions for react-permission-role
      setUser({
        id: user.id,
        roles: roles,
        permissions: permissions,
      });

      // Redirect to the dashboardRoot path after login
      navigate(paths.mainDashboard);

      toast.success(`Logged in as ${email}`, {
        theme: "colored",
      });
    } catch (error) {
      toast.error(error.data?.detail || "Failed to log in", {
        theme: "colored",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthCardLayout
      leftSideContent={
        <>
          <p className="text-white">
            Don't have an account?
            <br />
            <Link className="text-white text-decoration-underline" to={paths.cardRegister}>
              Register
            </Link>
          </p>
          <p className="pt-3 text-white">
            Have a technical issue?
            <br />
            <Link className="text-white text-decoration-underline" to={paths.cardTechnicalIssue}>
              Raise a ticket
            </Link>
          </p>
        </>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel onSubmit={handleLogin} />
    </AuthCardLayout>
  );
};

export default Login;
