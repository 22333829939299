import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../authorization/authService";

export const reportScheduleApi = createApi({
  reducerPath: "reportScheduleApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ReportSchedule"],
  endpoints: (builder) => ({
    // Fetch all paginated report schedules
    getReportSchedules: builder.query({
      query: ({ page, size }) => ({
        url: `/report_schedule/list`,
        method: "GET",
        params: { page, size },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.items.map(({ id }) => ({ type: "ReportSchedule", id })),
              { type: "ReportSchedule", id: "LIST" },
            ]
          : [{ type: "ReportSchedule", id: "LIST" }],
    }),

    // Fetch a single report schedule by ID
    getReportScheduleById: builder.query({
      query: (id) => ({
        url: `/report_schedule/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "ReportSchedule", id }],
    }),

    // Create a new report schedule
    createReportSchedule: builder.mutation({
      query: (reportScheduleData) => ({
        url: `/report_schedule`,
        method: "POST",
        body: reportScheduleData,
      }),
      invalidatesTags: [{ type: "ReportSchedule", id: "LIST" }],
    }),

    // Fetch report schedules for a specific call by call_id
    getReportScheduleForCall: builder.query({
      query: ({ call_id, page, size }) => ({
        url: `/report_schedule/${call_id}/list`,
        method: "GET",
        params: { page, size },
      }),
      providesTags: (result, error, { call_id }) =>
        result
          ? [
              ...result.data.items.map(({ id }) => ({ type: "ReportSchedule", id })),
              { type: "ReportSchedule", id: `CALL_${call_id}` },
            ]
          : [{ type: "ReportSchedule", id: `CALL_${call_id}` }],
    }),

    // Update an existing report schedule by ID
    updateReportSchedule: builder.mutation({
      query: ({ id, reportScheduleData }) => ({
        url: `/report_schedule/${id}`,
        method: "PUT",
        body: reportScheduleData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "ReportSchedule", id }],
    }),
  }),
});

export const {
  useGetReportSchedulesQuery,
  useGetReportScheduleByIdQuery,
  useCreateReportScheduleMutation,
  useGetReportScheduleForCallQuery,
  useUpdateReportScheduleMutation,
} = reportScheduleApi;
