import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";

import InputMask from "react-input-mask";
import { useSubmitUnregisteredUserFeedbackMutation } from "components/services/feedback/feedbackService";

const TechnicalIssueForm = ({ hasLabel }) => {
  const navigate = useNavigate();

  // State
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    comments: "",
    file: null, // File state to handle uploads
  });

  const [createFeedback, { isLoading }] = useSubmitUnregisteredUserFeedbackMutation();

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Check if the telephone number is completely filled
    if (formData.telephone.includes("X")) {
      newErrors.telephone = "Please complete the telephone number.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop submission if there are errors
    }

    const feedbackData = new FormData();
    feedbackData.append("first_name", formData.firstName);
    feedbackData.append("last_name", formData.lastName);
    feedbackData.append("email", formData.email);
    feedbackData.append("phone", formData.telephone);
    feedbackData.append("comments", formData.comments);
    if (formData.file) {
      feedbackData.append("uploaded_file", formData.file);
    }

    try {
      await createFeedback(feedbackData).unwrap();
      toast.success(`Successfully submitted as ${formData.firstName} ${formData.lastName}`, {
        theme: "colored",
      });
      navigate("/"); // Redirect after successful submission
    } catch (error) {
      toast.error(`Failed to submit: ${error.data?.detail}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the form data state
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "telephone") {
      const error =
        /^\d+$/.test(value) || value === "" ? null : "Telephone number must contain only digits.";
      setErrors((prev) => ({ ...prev, telephone: error }));
    }
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      file: e.target.files[0], // Only one file
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "First Name" : ""}
          value={formData.firstName}
          name="firstName"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Last Name" : ""}
          value={formData.lastName}
          name="lastName"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Telephone</Form.Label>}
        <InputMask
          mask="+\977 (999) 999 99 99"
          maskChar="X"
          value={formData.telephone}
          onChange={(e) => setFormData((prev) => ({ ...prev, telephone: e.target.value }))}
        >
          {() => (
            <Form.Control
              placeholder={!hasLabel ? "Telephone" : ""}
              type="text"
              isInvalid={!!errors.telephone}
            />
          )}
        </InputMask>
        <Form.Control.Feedback type="invalid">{errors.telephone}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Comments</Form.Label>}
        <Form.Control
          as="textarea"
          rows={3}
          name="comments"
          value={formData.comments}
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Upload File (optional)</Form.Label>}
        <Form.Control type="file" onChange={handleFileChange} />
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.email ||
            Object.values(errors).some((error) => error !== null)
          }
        >
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};

TechnicalIssueForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default TechnicalIssueForm;
