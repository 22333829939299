export const MODAL_TEXTS = {
  CONFIRM_DEACTIVATION_TITLE: "Confirm Deactivation",
  CONFIRM_DEACTIVATION_TEXT:
    "Are you sure you want to deactivate this account? This action cannot be undone.",
  CANCEL_BUTTON: "Cancel",
  DEACTIVATION_BUTTON: "Deactivate",
};

export const PHONE_INPUT_MASK = [
  "+",
  "9",
  "7",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
];
