import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";

const SaasRevenue = ({ data }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center">
          <Col className="d-md-flex d-lg-block flex-between-center">
            <h6 className="mb-md-0 mb-lg-2">Pending Users</h6>
          </Col>
          <Col xs="auto">
            <h4 className="fs-6 fw-normal text-700">
              <CountUp start={0} end={data} duration={2.75} decimals={0} />
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SaasRevenue.propTypes = {
  data: PropTypes.number.isRequired,
};

export default SaasRevenue;
