import React from "react";
import { Table } from "react-bootstrap";

const ParticipationTable = ({ isLoading, error, data }) => {
  if (isLoading) {
    return <div>Loading Participation Data...</div>;
  }
  if (error) {
    return <div>Error loading participation data.</div>;
  }

  return (
    <div className="mt-4">
      <h5 className="my-4">Participation in Other Calls</h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Call ID</th>
            <th>Status</th>
            <th>Loan amount</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.id}>
              <td>Application</td>
              <td>{item.id_call_application}</td>
              <td>{item.status}</td>
              <td>{item.application_amnt}</td>
              <td>{new Date(item.updated_at).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ParticipationTable;
