import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useGetUserAnalyticsStatusesQuery } from "components/services/users/userService";
import SaasActiveUser from "components/dashboards/saas/SaasActiveUser";
import SaasRevenue from "components/dashboards/saas/SaasRevenue";
import SaasConversion from "components/dashboards/saas/SaasConversion";

const UserManagementStatistics = () => {
  const [userAnalyticsData, setUserAnalyticsData] = useState(null);

  const {
    data: userAnalyticsStatuses,
    isLoading: isUserAnalyticsStatusesLoading,
    error: userAnalyticsStatusesLoadingError,
  } = useGetUserAnalyticsStatusesQuery();

  // Update state when API data is available
  useEffect(() => {
    if (userAnalyticsStatuses) setUserAnalyticsData(userAnalyticsStatuses);
  }, [userAnalyticsStatuses]);

  // Extract values for active users, pending users, and pending roles
  const activeUsers =
    userAnalyticsData?.user_role_summary.find((status) => status.name === "Active Users")?.value ??
    0; // Default to 0

  const pendingUsers =
    userAnalyticsData?.user_role_summary.find((status) => status.name === "Pending Users")?.value ??
    0; // Default to 0

  const pendingRoles =
    userAnalyticsData?.user_role_summary.find((status) => status.name === "Pending Roles")?.value ??
    0; // Default to 0

  return (
    <div>
      {isUserAnalyticsStatusesLoading ? (
        <p>Loading user analytics statuses...</p>
      ) : userAnalyticsStatusesLoadingError ? (
        <p>Error loading user analytics statuses.</p>
      ) : (
        <Row className="g-3 mb-3">
          <Col md={4}>
            <SaasActiveUser data={activeUsers} />
          </Col>
          <Col md={4}>
            <SaasRevenue data={pendingUsers} />
          </Col>
          <Col md={4}>
            <SaasConversion data={pendingRoles} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default UserManagementStatistics;
