import React, { useEffect, useState, forwardRef } from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import {
  useGetUsersQuery,
  useUpdateUserStatusMutation,
  useUpdateUserRoleStatusMutation,
} from "components/services/users/userService";
import FalconComponentCard from "components/common/FalconComponentCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SubtleBadge from "components/common/SubtleBadge";

import { PORTAL_ADMINISTRATOR } from "features/constants/roles";
import { MODAL_TEXTS } from "features/constants/users";
import { MODAL_TEXTS as MODAL_TEXTS_ROLE } from "features/constants/roles";

const UserManagement = () => {
  const [showAll, setShowAll] = useState(false);
  const authState = useSelector((state) => state.auth);
  const isPortalAdmin = authState.user?.roles?.some((role) => role.name === PORTAL_ADMINISTRATOR);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false); // Modal state for deactivation
  const [userToDeactivate, setUserToDeactivate] = useState(null); // Track selected user
  const [showDeactivateRoleModal, setShowDeactivateRoleModal] = useState(false);
  const [roleToDeactivate, setRoleToDeactivate] = useState({ userId: null, roleId: null }); // Store user and role ID

  const {
    data: usersData,
    error: usersDataError,
    isLoading: usersDataLoading,
    refetch,
  } = useGetUsersQuery(showAll);
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [updateUserRoleStatus] = useUpdateUserRoleStatusMutation();

  const data = usersData ? usersData.data.items : [];

  useEffect(() => {
    refetch();
  }, [showAll, refetch]);

  const statusColors = {
    Inactive: "secondary",
    "Pending Role Change Confirmation": "warning",
    Active: "success",
    Rejected: "danger",
  };

  const IconWithTooltip = forwardRef(({ icon, action, tooltip, ...rest }, ref) => (
    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
      <div ref={ref} {...rest} style={{ cursor: "pointer" }}>
        <FontAwesomeIcon icon={icon} />
      </div>
    </OverlayTrigger>
  ));

  const RoleIconWithTooltip = ({ icon, action, tooltip, ...rest }) => (
    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
      <Dropdown.Item {...rest}>
        <FontAwesomeIcon
          icon={icon}
          className={`me-2 ${
            action === "deactivate"
              ? "text-danger"
              : action === "approve"
              ? "text-success-emphasis"
              : "text-danger"
          }`}
        />
        {tooltip}
      </Dropdown.Item>
    </OverlayTrigger>
  );

  const columns = [
    {
      accessorKey: "name",
      header: "User",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => `${original.first_name} ${original.last_name}`,
    },
    {
      accessorKey: "email",
      header: "Email",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => original.email,
    },
    {
      accessorKey: "bank.name",
      header: "Bank",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => original.bank.name,
    },
    {
      accessorKey: "status",
      header: "Status",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => (
        <SubtleBadge pill bg={statusColors[original.status]}>
          <span>{original.status}</span>
        </SubtleBadge>
      ),
    },
    {
      accessorKey: "roles",
      header: "Roles",
      meta: {
        headerProps: { className: "text-900" },
      },
      cell: ({ row: { original } }) => (
        <>
          {original.roles.map((role) => (
            <Dropdown key={role.id}>
              <Dropdown.Toggle variant="link" className="p-0 m-0">
                <SubtleBadge key={role.id} pill bg={statusColors[role.status]} className="me-1">
                  {role.name}
                </SubtleBadge>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {role.status === "Active" || role.status === "Pending Role Change Confirmation" ? (
                  <>
                    <RoleIconWithTooltip
                      icon={faUserMinus}
                      action="deactivate"
                      tooltip="Deactivate Role"
                      onClick={() => handleDeactivateRoleClick(original.id, role.id)}
                    />
                    {role.status === "Pending Role Change Confirmation" && (
                      <>
                        <RoleIconWithTooltip
                          icon={faThumbsUp}
                          action="approve"
                          tooltip="Approve Role"
                          onClick={() => handleRoleAction(original.id, role.id, "approve")}
                        />
                        <RoleIconWithTooltip
                          icon={faThumbsDown}
                          action="reject"
                          tooltip="Reject Role"
                          onClick={() => handleRoleAction(original.id, role.id, "reject")}
                        />
                      </>
                    )}
                  </>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </>
      ),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      meta: {
        headerProps: { className: "text-900 text-start" },
        cellProps: { className: "text-center" },
      },
      cell: ({ row: { original } }) => {
        const actions = [];
        if (original.status === "Active") {
          actions.push(
            <IconWithTooltip
              key={`deactivate-${original.id}`}
              icon={faUserMinus}
              action="deactivate"
              tooltip="Deactivate User"
              className="text-danger me-2"
              onClick={() => handleDeactivateClick(original.id)}
            />
          );
        }
        if (original.status === "Pending Role Change Confirmation") {
          actions.push(
            <IconWithTooltip
              key={`approve-${original.id}`}
              icon={faThumbsUp}
              action="approve"
              tooltip="Approve User"
              className="text-success me-2"
              onClick={() => handleAction(original.id, "approve")}
            />,
            <IconWithTooltip
              key={`reject-${original.id}`}
              icon={faThumbsDown}
              action="reject"
              tooltip="Reject User"
              className="text-danger"
              onClick={() => handleAction(original.id, "reject")}
            />
          );
        }
        return <div className="d-flex justify-content-center">{actions}</div>;
      },
    },
  ];

  const handleAction = async (userId, action) => {
    let status;
    switch (action) {
      case "deactivate":
        status = "Inactive";
        break;
      case "approve":
        status = "Active";
        break;
      case "reject":
        status = "Rejected";
        break;
      default:
        return;
    }

    try {
      await updateUserStatus({ userId, status }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update user status:", error);
      const errorMsg = error.data?.detail?.[0]?.msg || "Failed to update user status";
      toast.error(`Error: ${errorMsg}`);
    }
  };

  const handleRoleAction = async (userId, roleId, action) => {
    let status;
    switch (action) {
      case "deactivate":
        status = "Inactive";
        break;
      case "approve":
        status = "Active";
        break;
      case "reject":
        status = "Rejected";
        break;
      default:
        return;
    }

    try {
      await updateUserRoleStatus({ userId, roleId, status }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update role status:", error);
      const errorMsg = error.data?.detail?.[0]?.msg || "Failed to update role status";
      toast.error(`Error: ${errorMsg}`);
    }
  };

  const handleDeactivateClick = (userId) => {
    setUserToDeactivate(userId); // Set the user to be deactivated
    setShowDeactivateModal(true); // Show the modal
  };

  const confirmUserDeactivation = async () => {
    if (userToDeactivate) {
      await handleAction(userToDeactivate, "deactivate");
      setShowDeactivateModal(false); // Close the modal
    }
  };

  // Trigger the modal when deactivating a role
  const handleDeactivateRoleClick = (userId, roleId) => {
    setRoleToDeactivate({ userId, roleId });
    setShowDeactivateRoleModal(true);
  };

  // Confirm role deactivation
  const confirmRoleDeactivation = async () => {
    if (roleToDeactivate.userId && roleToDeactivate.roleId) {
      await handleRoleAction(roleToDeactivate.userId, roleToDeactivate.roleId, "deactivate");
      setShowDeactivateRoleModal(false); // Close the modal after deactivation
    }
  };

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
    globalFilter: true,
  });

  return (
    <>
      <FalconComponentCard className="mb-0">
        <FalconComponentCard.Header
          title="User Management"
          light={false}
          className="border-bottom border-200"
        />
        <FalconComponentCard.Body>
          <AdvanceTableProvider {...table}>
            <Row className="center mt-1 mb-2">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox />
              </Col>
              {isPortalAdmin && (
                <Col xs="auto" className="ms-auto">
                  <Form.Check
                    type="checkbox"
                    id="show-all-users"
                    label="Show All Users"
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    className="mb-3"
                  />
                </Col>
              )}
            </Row>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
            </div>
          </AdvanceTableProvider>
        </FalconComponentCard.Body>
      </FalconComponentCard>
      {/* Deactivate User Modal */}
      <Modal show={showDeactivateModal} onHide={() => setShowDeactivateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TEXTS.CONFIRM_DEACTIVATION_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{MODAL_TEXTS.CONFIRM_DEACTIVATION_TEXT}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeactivateModal(false)}>
            {MODAL_TEXTS.CANCEL_BUTTON}
          </Button>
          <Button variant="danger" onClick={confirmUserDeactivation}>
            {MODAL_TEXTS.DEACTIVATION_BUTTON}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Deactivate Role Modal */}
      <Modal
        show={showDeactivateRoleModal}
        onHide={() => setShowDeactivateRoleModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TEXTS_ROLE.CONFIRM_ROLE_DEACTIVATION_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{MODAL_TEXTS_ROLE.CONFIRM_ROLE_DEACTIVATION_TEXT}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeactivateRoleModal(false)}>
            {MODAL_TEXTS_ROLE.CANCEL_BUTTON}
          </Button>
          <Button variant="danger" onClick={confirmRoleDeactivation}>
            {MODAL_TEXTS_ROLE.ROLE_DEACTIVATION_BUTTON}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserManagement;
